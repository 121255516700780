* {
    box-sizing: border-box;
}

body, html {
    margin: 0;
    padding: 0;
    background-color: @w-100;

    font-family: @font-family;
    color: @font-colour;
    font-size: @font-size;
    line-height: 1.45;
    text-rendering: geometricPrecision;
}

html.noscroll {
    overflow: hidden;
}

body.noscroll {
    overflow: visible;
    height: 100%;
}

body.public {
    animation: bodyfadein 0.3s;
}

@keyframes bodyfadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

img {
	border: none;
	max-width: 100%;
	height: auto;
}