/*  ====== Theme Variables ======
    Unique to this project
    ========================================================================= */
* {
  box-sizing: border-box;
}
body,
html {
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  font-family: 'Quicksand', sans-serif;
  color: #3e4b53;
  font-size: 18px;
  line-height: 1.45;
  text-rendering: geometricPrecision;
}
html.noscroll {
  overflow: hidden;
}
body.noscroll {
  overflow: visible;
  height: 100%;
}
body.public {
  animation: bodyfadein 0.3s;
}
@keyframes bodyfadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
img {
  border: none;
  max-width: 100%;
  height: auto;
}
a {
  transition: all 0.7s cubic-bezier(0.165, 0.84, 0.44, 1);
  color: #0098DB;
  text-decoration: none;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}
a:hover {
  color: #0075a8;
  text-decoration: underline;
}
p {
  margin: 0;
  padding: 0;
}
p strong {
  font-weight: bold;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 10px 0;
  padding: 0;
  font-family: 'Karla', sans-serif;
  font-weight: 700;
  letter-spacing: -2px;
  line-height: 1.2;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  display: inline-block;
}
h1 small:before,
h2 small:before,
h3 small:before,
h4 small:before,
h5 small:before,
h6 small:before {
  content: ' ';
}
h1 > a,
h2 > a,
h3 > a,
h4 > a,
h5 > a,
h6 > a,
a > h1,
a > h2,
a > h3,
a > h4,
a > h5,
a > h6 {
  color: #0098DB;
  text-decoration: none;
  font-weight: inherit;
}
h1 {
  font-size: 51.4098px;
}
h2 {
  font-size: 39.546px;
}
h3 {
  font-size: 30.42px;
}
h4 {
  font-size: 23.4px;
}
h5 {
  font-size: 18px;
}
h6 {
  font-size: 18px;
}
blockquote {
  margin: 0;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  font-family: 'Karla', sans-serif;
  font-size: 30.42px;
  font-weight: 700;
  letter-spacing: -1px;
  line-height: 1.2;
  color: #546570;
}
cite,
p.u-cite {
  font-family: 'Karla', sans-serif;
  font-style: normal;
  font-size: 18px;
  letter-spacing: 0px;
  text-transform: uppercase;
  background-color: #f7f6f6;
}
p.u-cite {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 30px;
  font-weight: 700;
}
ul,
ol {
  margin: 0;
  padding: 0;
}
ul li,
ol li {
  margin-bottom: 21.6px;
  margin-left: 21.6px;
}
hr {
  border: none;
  border-top: 1px #f7f6f6 solid;
}
@media screen and (max-width: 768px) {
  blockquote {
    font-size: 30.42px;
  }
}
@media screen and (max-width: 576px) {
  h1 {
    font-size: 39.546px;
    letter-spacing: -2px;
  }
  h2 {
    font-size: 30.42px;
  }
  blockquote {
    font-size: 23.4px;
  }
  cite,
  p.u-cite {
    font-size: 18px;
    letter-spacing: -1px;
  }
}
.Box--round {
  border-radius: 4px;
}
.Box--shadow {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}
.u-divider {
  position: relative;
  display: block;
  top: 1px;
  width: 1px;
  height: 90px;
  margin-top: 90px;
  margin-bottom: 90px;
  margin-left: auto;
  margin-right: auto;
  background-color: #f7f6f6;
}
@media screen and (max-width: 768px) {
  .u-divider {
    margin-top: 0;
    margin-bottom: 45px;
  }
}
.u-content a:active {
  text-decoration: none;
}
.u-content p {
  margin-bottom: 0.5em;
}
.u-content p small {
  margin-bottom: 0.5em;
}
.u-content h1,
.u-content h2,
.u-content h3,
.u-content h4,
.u-content h5,
.u-content h6 {
  margin-bottom: 20px;
}
.u-content p:last-child,
.u-content h1:last-child,
.u-content h2:last-child,
.u-content h3:last-child,
.u-content h4:last-child,
.u-content h5:last-child,
.u-content h6:last-child {
  margin-bottom: 0;
}
.u-content blockquote,
.u-content cite {
  background-color: #f7f6f6;
}
.u-indent p {
  text-indent: 30px;
}
.u-content--positive {
  color: #ffffff;
}
.u-content--positive p {
  color: #ffffff;
}
.u-content--positive h1,
.u-content--positive h2,
.u-content--positive h3,
.u-content--positive h4,
.u-content--positive h5,
.u-content--positive h6 {
  color: #ffffff;
}
.u-content--positive h1 small,
.u-content--positive h2 small,
.u-content--positive h3 small,
.u-content--positive h4 small,
.u-content--positive h5 small,
.u-content--positive h6 small {
  color: #ffffff;
}
.u-content--positive a {
  color: #ffffff;
}
.u-content--positive a:hover {
  color: #e6e6e6;
}
.u-content--negative {
  color: #333;
}
.u-content--negative p {
  color: #333;
}
.u-content--negative h1,
.u-content--negative h2,
.u-content--negative h3,
.u-content--negative h4,
.u-content--negative h5,
.u-content--negative h6 {
  color: #333;
}
.u-content--negative h1 small,
.u-content--negative h2 small,
.u-content--negative h3 small,
.u-content--negative h4 small,
.u-content--negative h5 small,
.u-content--negative h6 small {
  color: #ffffff;
}
.u-content--negative a {
  color: #333;
}
.u-content--negative a:hover {
  color: #1a1a1a;
}
.u-content--lg {
  font-size: 1.1em;
}
@media screen and (max-width: 576px) {
  .u-content--lg {
    font-size: 18px;
  }
}
.u-content--sm {
  font-size: 0.9em;
}
.u-content--xs {
  font-size: 0.8em;
}
img.u-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
img.u-left {
  display: block;
  float: left;
  margin-right: 30px;
}
img.u-right {
  display: block;
  float: right;
  margin-left: 30px;
}
.u-container {
  position: relative;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-right: 16px;
  padding-left: 16px;
}
.u-container-fluid {
  position: relative;
  padding-right: 16px;
  padding-left: 16px;
}
@media only screen and (min-width: 576px) {
  .u-container {
    width: 560px;
    max-width: 100%;
  }
}
@media only screen and (min-width: 768px) {
  .u-container {
    width: 752px;
    max-width: 100%;
  }
}
@media only screen and (min-width: 992px) {
  .u-container {
    width: 976px;
    max-width: 100%;
  }
}
@media only screen and (min-width: 1200px) {
  .u-container {
    width: 1184px;
    max-width: 100%;
  }
}
.u-row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
}
.u-double {
  max-width: 50%;
  flex-basis: 50%;
}
@media screen and (max-width: 768px) {
  .u-double {
    max-width: 100%;
    flex-basis: 100%;
  }
}
.u-triple {
  max-width: 33.333%;
  flex-basis: 33.333%;
}
@media screen and (max-width: 1200px) {
  .u-triple {
    max-width: 50%;
    flex-basis: 50%;
  }
}
@media screen and (max-width: 768px) {
  .u-triple {
    max-width: 100%;
    flex-basis: 100%;
  }
}
.u-quad {
  max-width: 25%;
  flex-basis: 25%;
}
@media screen and (max-width: 1200px) {
  .u-quad {
    max-width: 50%;
    flex-basis: 50%;
  }
}
@media screen and (max-width: 768px) {
  .u-quad {
    max-width: 100%;
    flex-basis: 100%;
  }
}
.u-full {
  max-width: 100%;
  flex-basis: 100%;
}
.u-ml {
  margin-left: auto;
}
.u-mr {
  margin-right: auto;
}
.u-block {
  display: block;
}
hr.u-spacer {
  border: none;
  margin: 0;
}
p.u-lead {
  font-size: 1.4em;
  font-weight: 300;
}
h1.u-lead,
h2.u-lead,
h3.u-lead,
h4.u-lead,
h5.u-lead,
h6.u-lead,
.u-leadHeading {
  border-bottom: 1px solid #0098DB;
}
.u-textLeft {
  text-align: left;
}
.u-textRight {
  text-align: right;
}
.u-textCenter {
  text-align: center;
}
.u-textJustify {
  text-align: justify;
}
.u-textNoWrap {
  white-space: nowrap;
}
@media screen and (max-width: 768px) {
  .u-textCenter--sm {
    text-align: center;
  }
  .u-textleft--sm {
    text-align: left;
  }
  .u-textRight--sm {
    text-align: right;
  }
}
@media screen and (max-width: 768px) {
  .u-textCenter--md {
    text-align: center;
  }
  .u-textleft--md {
    text-align: left;
  }
  .u-textRight--md {
    text-align: right;
  }
}
/**
 * Enables font kerning in all browsers.
 * http://blog.typekit.com/2014/02/05/kerning-on-the-web/
 *
 * 1. Chrome (not Windows), Firefox, IE 10+
 * 2. Safari 7 and future browsers
 * 3. Chrome (not Windows), Firefox, Safari 6+, iOS, Android
 */
.u-textKern {
  font-feature-settings: "kern" 1;
  /* 1 */
  font-kerning: normal;
  /* 2 */
  text-rendering: optimizeLegibility;
  /* 3 */
}
/**
 * Text truncation
 *
 * Prevent text from wrapping onto multiple lines, and truncate with an
 * ellipsis.
 *
 * 1. Ensure that the node has a maximum width after which truncation can
 *    occur.
 * 2. Fix for IE 8/9 if `word-wrap: break-word` is in effect on ancestor
 *    nodes.
 */
.u-textTruncate {
  max-width: 100%;
  /* 1 */
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  word-wrap: normal !important;
  /* 2 */
}
.u-textLowerCase {
  text-transform: lowercase;
}
.u-textUpperCase {
  text-transform: uppercase;
}
.u-textCapitalize {
  text-transform: capitalize;
}
.u-noJsShow {
  display: none !important;
}
.no-js .u-noJsShow {
  display: block !important;
}
.no-js .u-noJsHide {
  display: none !important;
}
.mce-content-body {
  padding: 15px;
}
.mce-content-body a:active {
  text-decoration: none;
}
.mce-content-body p {
  margin-bottom: 0.5em;
}
.mce-content-body p small {
  margin-bottom: 0.5em;
}
.mce-content-body h1,
.mce-content-body h2,
.mce-content-body h3,
.mce-content-body h4,
.mce-content-body h5,
.mce-content-body h6 {
  margin-bottom: 20px;
}
.mce-content-body p:last-child,
.mce-content-body h1:last-child,
.mce-content-body h2:last-child,
.mce-content-body h3:last-child,
.mce-content-body h4:last-child,
.mce-content-body h5:last-child,
.mce-content-body h6:last-child {
  margin-bottom: 0;
}
.mce-content-body blockquote,
.mce-content-body cite {
  background-color: #f7f6f6;
}
