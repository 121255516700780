.u-container {
    position: relative;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    padding-right: 16px;
    padding-left: 16px;
}

.u-container-fluid {
    position: relative;
    padding-right: 16px;
    padding-left: 16px;
}

@media only screen and (min-width: 576px) {
  .u-container {
    width: 560px;
    max-width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .u-container {
    width: 752px;
    max-width: 100%;
  }
}

@media only screen and (min-width: 992px) {
  .u-container {
    width: 976px;
    max-width: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  .u-container {
    width: 1184px;
    max-width: 100%;
  }
}

.u-row {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
}

.u-double {
    max-width: 50%;
    flex-basis: 50%;

    @media screen and (max-width: @screen-sm) {
        max-width: 100%;
        flex-basis: 100%;
    }
}

.u-triple {
    max-width: 33.333%;
    flex-basis: 33.333%;

    @media screen and (max-width: @screen-lg) {
        max-width: 50%;
        flex-basis: 50%;
    }

    @media screen and (max-width: @screen-sm) {
        max-width: 100%;
        flex-basis: 100%;
    }
}

.u-quad {
    max-width: 25%;
    flex-basis: 25%;

    @media screen and (max-width: @screen-lg) {
        max-width: 50%;
        flex-basis: 50%;
    }

    @media screen and (max-width: @screen-sm) {
        max-width: 100%;
        flex-basis: 100%;
    }
}

.u-full {
    max-width: 100%;
    flex-basis: 100%;
}

.u-ml {
    margin-left: auto;
}

.u-mr {
    margin-right: auto;
}

.u-block {
    display: block;
}


// Handy spacer class for adding space between items
.u-spacer(@space: 30px; @space-ratio: 2) {
    display: block;
    height: @space;
    width: @space;
    visibility: hidden;

    &--xs {
        @size: @space / (2 * @space-ratio);
        height: @size;
        width: @size;
    }

    &--sm {
        @size: @space / (1 * @space-ratio);
        height: @size;
        width: @size;
    }

    &--lg {
        @size: @space * (@space-ratio * 1);
        height: @size;
        width: @size;
    }

    &--xl {
        @size: @space * (@space-ratio * 2);
        height: @size;
        width: @size;
    }

    &--xxl {
        @size: @space * (@space-ratio * 3);
        height: @size;
        width: @size;
    }
}

hr.u-spacer {
    border: none;
    margin: 0;
}
