// Use to hide or display items depending on JS support

// JS enabled
.u-noJsShow {
    display: none !important;
}

// JS disabled
.no-js {

    .u-noJsShow {
        display: block !important;
    }

    .u-noJsHide {
        display: none !important;
    }
}
