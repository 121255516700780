
// The content utility is mainly used for areas where a user can add content.
// It provides nice styles for basic content elements like text and images.
// This allows us to have user-friendly styles for certain areas without having them apply to the page as a whole.
.u-divider {
    position: relative;
    display: block;
    top: 1px;
    width: 1px;
    height: 90px;
    margin-top: @space*3;
    margin-bottom: @space*3;
    margin-left: auto;
    margin-right: auto;
    background-color: @n-100;

    @media screen and (max-width: @screen-sm) {
        margin-top: 0;
        margin-bottom: @space*1.5;
    }
}

.u-content {

    a:active {
        text-decoration: none;
    }

    p {
        margin-bottom: 0.5em;
        small {
            margin-bottom: 0.5em;
        }
    }

    h1, h2, h3, h4, h5, h6 {
        margin-bottom: 20px;
    }

    p, h1, h2, h3, h4, h5, h6 {
        &:last-child {
            margin-bottom: 0;
        }
    }
    
    blockquote, cite {
        background-color: @n-100;
    }
}

.u-indent {
    p {
        text-indent: @space;
    }
}

// We declare modifiers here rather than nested under .u-content so they can be used as mixins.
// (LESS is'nt clever enough to auto-concat the nested name structure when addressing the mixin).

.u-content--positive {
    .typographyColor(@w-100);
}

.u-content--negative {
    .typographyColor(#333);
}

.u-content--lg {
    font-size: 1.1em;

    @media screen and (max-width: @screen-xs) {
        font-size: @font-size;
    }
}

.u-content--sm {
    font-size: 0.9em;
}

.u-content--xs {
    font-size: 0.8em;
}
