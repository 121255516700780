img {
    &.u-center {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    &.u-left {
        display: block;
        float: left;
        margin-right: @space;
    }

    &.u-right {
        display: block;
        float: right;
        margin-left: @space;
    }
}
